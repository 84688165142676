import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import ContentAds from "../adsense/ContentAds";
import Description from "../cities/components/Description";
import AddDate from "../components/AddDate";
import HeaderImages from "../components/HeaderImages";
import ImagesCarousel from "../components/ImagesCarousel";
import VerticalBanner from "../components/VerticalBanner";
import HeaderPage from "../components/common/HeaderPage";
import PageWrapper from "../components/common/PageWrapper";
import ItemCard from "../components/common/itemCard/ItemCard";
import ItemCardSkeleton from "../components/common/itemCard/ItemCardSkeleton";
import { MostPopular } from "../components/mostPopular/MostPopular";
import { GetNews, GetNewsQuery } from "../integration/apiRoutes";
import { useProfileRequest } from "../integration/httpHooks";
import { getMethod } from "../integration/httpService";
import HelmetHeader from "../utilis/HelmetHeader";

export const DefaultContentHeight = "20vh";
const Article = () => {
  const [articleId] = useQueryParam("id", NumberParam);
  const [cityId] = useQueryParam("cityId", NumberParam);

  const response = useQuery([GetNewsQuery, articleId], () => {
    return getMethod(GetNews(articleId));
  });
  const profileResponse = useProfileRequest(cityId as number);

  const isLoading = response.isLoading || profileResponse.isLoading;

  const { images, name, description, createdAt } = isLoading
    ? { images: [], name: "", description: "", createdAt: "" }
    : response.data;
  return (
    <Box display="flex" flex="1">
      <HelmetHeader
        isLoading={isLoading}
        title={name}
        description={description}
      />
      <PageWrapper direction="row">
        <Box
          sx={{
            flex: { xs: "1", md: "0.7" },
            minWidth: "1px",
          }}
        >
          <Box sx={{ flexDirection: "column", flex: 1, gap: 2 }}>
            <Box sx={{ minHeight: { xs: "auto", md: DefaultContentHeight } }}>
              <HeaderPage
                name={name}
                isLoading={isLoading}
                cityId={cityId}
                isBack={true}
              />
              <AddDate date={createdAt} />
              <HeaderImages isLoading={isLoading} images={images} />

              <Box sx={{ display: { md: "none" } }}>
                <ImagesCarousel images={images} />
              </Box>
              <Description
                isLoading={isLoading}
                content={description}
                hideShowMore
              />
            </Box>
            <MostPopular
              children={profileResponse.data?.news
                ?.filter((news: any) => news.id !== articleId)
                ?.slice(0, 10)
                ?.map((news: any) => (
                  <ItemCard
                    key={news.id}
                    linkTo={`/article?id=${news.id}&cityId=${cityId}`}
                    title={news.name}
                    description={news.description}
                    image={news.images?.[0]}
                  />
                ))}
              isLoading={isLoading}
              cardSkeleton={<ItemCardSkeleton />}
              numberOfChildrenSkeleton={3}
              isCarousel={false}
              title={"news.newest.title"}
              toAll={`/articles?cityId=${cityId}&page=1`}
              spaceInHeader={true}
            />
            <ContentAds />
          </Box>
        </Box>
        <VerticalBanner />
      </PageWrapper>
    </Box>
  );
};

export default Article;
