import { useTranslation } from "react-i18next";
import ItemLabelWithValue from "../ItemLabelWithValue";

interface AdditionalInfoI {
  additionalInformation: string;
}

const AdditionalInfo = (props: AdditionalInfoI) => {
  const { additionalInformation } = props;
  const { t } = useTranslation();

  if (!additionalInformation) {
    return null;
  }
  return (
    <ItemLabelWithValue
      minWidth={200}
      label={t("common.additionalInformation")}
      value={additionalInformation}
      flexDirection="column"
      alignItems="flex-start"
    />
  );
};

export default AdditionalInfo;
