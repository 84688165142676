import styled from "@emotion/styled";
import { Box } from "@mui/material";

const PageWrapper = styled(Box)<any>(({ theme, direction = "column" }) => ({
  width: "100%",
  display: "flex",
  flex: 1,
  gap: theme.spacing(5),
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(2)} 0`,
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    padding: `${theme.spacing(4)} 0`,
    flexDirection: direction,
  },
}));

export default PageWrapper;
