import { Box, useMediaQuery, useTheme } from "@mui/material";
import AdSensePlug from "../adsense/AdSensePlug";
import qrCode from "../images/qrCode.png";

const VerticalBanner = (props: any) => {
  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.up("md"));
  if (!show) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: 0.3,
        gap: 4,
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {/* <ImagesCarousel images={data?.images || []} /> */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        // flex="1"
      >
        <Box
          style={{
            backgroundImage: `url(${qrCode})`,
            height: 140,
            width: 140,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Box
          pl={1}
          sx={{ fontWeight: "lighter", textAlign: "center", fontSize: 32 }}
        >
          Ściągnij naszą aplikację
        </Box>
      </Box>
      {props.children}
      <AdSensePlug showLogo={false} mt={0} />
    </Box>
  );
};

export default VerticalBanner;
