import { Box, Skeleton } from "@mui/material";
import RowSpace from "../../common/OwnRow";
import HeaderMostPopular from "../components/HeaderMostPopular";

export const MostPopularSkeleton = ({
  mt,
  cardSkeleton,
  showCheckAll,
  numberOfChildren = 5,
  sx,
}: any) => {
  return (
    <Box sx={sx} py={2}>
      <HeaderMostPopular mt={mt} pb={1} spaceInHeader={false}>
        <Skeleton variant="text" style={{ width: 240, height: 28 }} />
        {showCheckAll && (
          <Skeleton variant="text" style={{ width: 160, marginLeft: 20 }} />
        )}
      </HeaderMostPopular>
      <RowSpace>
        {Array.from(Array(numberOfChildren).keys()).map((item) => (
          <Box key={item}>{cardSkeleton}</Box>
        ))}
      </RowSpace>
    </Box>
  );
};
