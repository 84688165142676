import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MapFilters = ({ callback, cityId }: any) => {
  const [state, setState] = useState({
    attractions: true,
    tours: true,
    events: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      [event.target.name]: event.target.checked,
    };
    setState(newState);
    callback(newState);
  };
  const { attractions, tours, events } = state;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
        <Typography>Filtry</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <FormControl component="fieldset" variant="standard">
            {/* <FormGroup row={true}> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attractions}
                    onChange={handleChange}
                    name="attractions"
                  />
                }
                label="Atrakacje"
              />
              {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {[].map((item) => (
                    <MenuItem
                      key={item.code}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tours}
                    onChange={handleChange}
                    name="tours"
                  />
                }
                label="Trasy"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={events}
                    onChange={handleChange}
                    name="events"
                  />
                }
                label="Wydarzenia"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MapFilters;
