export const parseDistance = (distance: number) => {
  if (!distance) {
    return "";
  }
  const km = distance / 1000;
  return `${km.toFixed(1)} km.`;
};

export const parseDuration = (time: number) => {
  if (!time) {
    return "";
  }
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  if (hours === 0) {
    return `${minutes.toFixed(0)} min.`;
  }
  return `${hours} godz. ${minutes.toFixed(0)} min.`;
};
