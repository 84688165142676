import styled from "@emotion/styled";
import { Box } from "@mui/material";

const SubtitleItemCard = styled(Box)<any>(({ theme, isBig }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  fontStyle: "italic",
  // fontWeight: "bold",
  fontSize: 14,
}));

export default SubtitleItemCard;
