import styled from "@emotion/styled";
import { Box } from "@mui/material";

const HeaderWrapper = styled(Box)<any>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

export default HeaderWrapper;
