import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "city.header.welcome": "Witaj, jesteś w",
          "city.description.readMore": "Dowiedz się wiecęj!",
          "city.description.readHide": "Zwiń",
          "city.news.title": "Aktualności",
          "city.tours.title": "Trasy",
          "city.events.title": "Wydarzenia",
          "city.attractions.title": "Atrakcje",
          "common.title.theMostPopular": "Najbardziej popularne",
          "common.checkAll": "Zobacz wszystkie",
          "common.check": "Sprawdź",
          "menu.mainPage": "Strona główna",
          "menu.yourCity": "Twoje Miasto",
          "search.title": "Odkrywaj nowe miejsca i trasy!",
          "search.showMap": "Pokaż mapę",
          "search.placeHolder": "Wprowadź nazwę",
          "dashboard.cities.theMostPopular": "Najbardziej popularne",
          "dashboard.cities.countUp": "Liczba miejscowości, które nam zaufały:",
          "dashboard.cities.isBuilding": "Już wkrótce",
          "attraction.title": "Atrakcje",
          "tours.title": "Trasy",
          "events.title": "Wydarzenia",
          "common.address": "Adres:",
          "common.wwwPage": "Strona internetowa:",
          "common.copy": " Adres został skopiowany!",
          "common.startDate": "Data rozpoczęcia:",
          "common.endDate": "Data zakończenia:",
          "common.openHours.title": "Godziny otwarcia",
          "common.tickets.title": "Bilety",
          "common.additionalInformation": "Dodatkowe informacje: ",
          "common.description.title": "Opis",
          "common.checkCity": "Odwiedź miasto",
          "news.newest.title": "Najnowsze wiadomości",
          "news.title": "Wiadomości",
          SUNDAY: "Niedziela:",
          SATURDAY: "Sobota:",
          FRIDAY: "Piątek:",
          THURSDAY: "Czwartek:",
          WEDNESDAY: "Środa:",
          TUESDAY: "Wtorek:",
          MONDAY: "Poniedziałek:",
          "tour.details.title": "Szczegóły:",
          "tour.details.distance": "Dystans:",
          "tour.details.duration": "Czas trwania:",
          "tour.details.numberOfAttractions": "Liczba atrakacji:",
          "tour.attractions.title": "Atrakcje na trasie:",
          "header.backToCity": "Profil Miasta",
          "openHours.close": "Zamknięte",
        },
      },
      // pl: {
      //   translation: {
      //     "city.header.welcome": "Witaj, jesteś w mieście:",
      //   },
      // },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
