import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import ContentAds from "../adsense/ContentAds";
import { DefaultContentHeight } from "../articles/Article";
import Description from "../cities/components/Description";
import HeaderImages from "../components/HeaderImages";
import ImagesCarousel from "../components/ImagesCarousel";
import ItemLabelWithValue from "../components/ItemLabelWithValue";
import OpenHours from "../components/OpenHours";
import Tickets from "../components/Tickets";
import VerticalBanner from "../components/VerticalBanner";
import HeaderPage from "../components/common/HeaderPage";
import PageWrapper from "../components/common/PageWrapper";
import ItemCard from "../components/common/itemCard/ItemCard";
import ItemCardSkeleton from "../components/common/itemCard/ItemCardSkeleton";
import { MostPopular } from "../components/mostPopular/MostPopular";
import {
  GetAttractionId,
  GetAttractionIdQuery,
} from "../integration/apiRoutes";
import { useProfileRequest } from "../integration/httpHooks";
import { getMethod } from "../integration/httpService";
import HelmetHeader from "../utilis/HelmetHeader";

const Attraction = () => {
  const [attractionId] = useQueryParam("id", NumberParam);
  const [cityId] = useQueryParam("cityId", NumberParam);

  const response = useQuery([GetAttractionIdQuery, attractionId], () => {
    return getMethod(GetAttractionId(attractionId));
  });
  const profileResponse = useProfileRequest(cityId as number);

  const isLoading = profileResponse.isLoading || response.isLoading;

  const { images, name, description, address, openTime, ticket, url } =
    isLoading
      ? {
          images: [],
          name: "",
          description: "",
          address: "",
          openTime: [],
          ticket: "",
          url: "",
        }
      : response.data;

  return (
    <Box display="flex" flex="1">
      <HelmetHeader
        isLoading={isLoading}
        title={name}
        description={description}
      />

      <PageWrapper direction="row">
        <Box sx={{ flex: { xs: "1", md: "0.7" }, minWidth: "1px" }}>
          <Box
            sx={{ minHeight: { xs: "auto", md: DefaultContentHeight } }}
            pb={2}
          >
            <HeaderPage
              isLoading={isLoading}
              name={name}
              cityId={cityId}
              isBack={true}
            />
            <ItemLabelWithValue
              label="common.address"
              value={address}
              icon={<PlaceIcon color="secondary" />}
            />
            <HeaderImages isLoading={isLoading} images={images} />
            <Box>{/* Ecstract to new component */}</Box>
            <Box sx={{ display: { md: "none" } }}>
              <ImagesCarousel images={images} />
            </Box>
            <Description
              isLoading={isLoading}
              content={description}
              showTitle
            />

            {url && (
              <ItemLabelWithValue
                label="common.wwwPage"
                value={url}
                icon={<LanguageIcon color="secondary" />}
              />
            )}
            <OpenHours isLoading={isLoading} openTime={openTime} />
            <Tickets isLoading={isLoading} ticket={ticket} />
          </Box>
          <MostPopular
            mt={2}
            children={profileResponse?.data?.attractions
              ?.filter((att: any) => att.id !== attractionId)
              ?.slice(0, 10)
              ?.map((attraction: any) => (
                <ItemCard
                  key={attraction.id}
                  linkTo={`/attraction?id=${attraction.id}&cityId=${cityId}`}
                  title={attraction.name}
                  description={attraction.description}
                  image={attraction.mainImage}
                />
              ))}
            isCarousel={false}
            title={"city.attractions.title"}
            toAll={`/attractions?cityId=${cityId}&page=1`}
            spaceInHeader={false}
            isLoading={isLoading}
            cardSkeleton={<ItemCardSkeleton />}
            numberOfChildrenSkeleton={3}
          />
          <ContentAds />
        </Box>
        <VerticalBanner />
      </PageWrapper>
    </Box>
  );
};

export default Attraction;
