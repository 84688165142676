import { Box } from "@mui/material";
import linkifyHtml from "linkify-html";

interface BuyHereI {
  url: string;
}

const BuyHere = (props: BuyHereI) => {
  const { url } = props;
  if (!url) {
    return null;
  }

  return <Box dangerouslySetInnerHTML={{ __html: linkifyHtml(url) }} />;
};

export default BuyHere;
