import { Skeleton } from "@mui/material";
interface TextCardSkeletonI {
  isBig?: boolean;
  width?: number | string;
}
const TextCardSkeleton = ({ isBig, width = 90 }: TextCardSkeletonI) => (
  <Skeleton variant="text" style={{ width, fontSize: isBig ? 23 : 16 }} />
);

export default TextCardSkeleton;
