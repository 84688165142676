import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent, {
  timelineContentClasses,
} from "@mui/lab/TimelineContent";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { imageUrl } from "../integration/httpService";

interface AttractionsStepsI {
  attractions: any[];
  cityId: number;
  isLoading: boolean;
}

const AttractionsSteps = ({
  attractions,
  cityId,
  isLoading,
}: AttractionsStepsI) => {
  const navigate = useNavigate();
  if (isLoading) {
    return null;
  }
  return (
    // @ts-ignore
    <Timeline sx={{ marginTop: 0, paddingTop: 0 }}>
      {attractions.map((att: any, index: number) => (
        <TimelineItem
          key={att.id}
          onClick={() => navigate(`/attraction?id=${att.id}&cityId=${cityId}`)}
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0,
            },
            [`& .${timelineContentClasses.root}`]: {
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            },
            transition: "transform 0.15s ease-in-out",
            "&:hover": {
              transform: "scale3d(1.05, 1.05, 1)",
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            color="text.secondary"
          >
            {index + 1}.
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot variant="outlined" color="secondary">
              <Avatar
                sx={{ width: 50, height: 50 }}
                src={imageUrl(att.mainImage.url)}
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              px: 2,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {att.name}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default AttractionsSteps;
