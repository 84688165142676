import { Box } from "@mui/material";
import { useMemo } from "react";
import { hasEventStarted } from "../../../utilis/HasEventStarted";
import Card from "../Card";
import { PulseDot } from "../PulseDot";
import DateItemCard from "./DateItemCard";
import DescriptionItemCard from "./DescriptionItemCard";
import TitleItemCard from "./TitleItemCard";

interface ItemCardI {
  title: string;
  image: any;
  description: string;
  startDate?: string;
  linkTo: string;
  numberOfLines?: number;
}

export const MaxHeightItemCard = 260;
export const ItemCardWidth = 300;
export const ImageItemCardHeight = (ItemCardWidth * 9) / 16;

const ItemCard = (props: ItemCardI) => {
  const { title, image, description, startDate, linkTo, numberOfLines } = props;
  const hasStarted = useMemo(() => {
    return !!startDate && hasEventStarted(startDate);
  }, [startDate]);
  return (
    <Card
      linkTo={linkTo}
      sx={{
        width: ItemCardWidth,
        minWidth: ItemCardWidth,
      }}
      image={image}
      statusIndicator={
        hasStarted && (
          <PulseDot
            sx={{
              top: "16px",
              right: "16px",
            }}
          />
        )
      }
    >
      <TitleItemCard isBig>{title}</TitleItemCard>
      <Box>
        {startDate && <DateItemCard startDate={startDate} />}
        <DescriptionItemCard numberOfLines={numberOfLines}>
          {description}
        </DescriptionItemCard>
      </Box>
    </Card>
  );
};

export default ItemCard;
