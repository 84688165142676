import { Box, Button, Skeleton, Typography } from "@mui/material";
import linkifyHtml from "linkify-html";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import SectionTitleWithTranslation from "../../components/section/SectionTitleWithTranslation";
import SectionWrapper from "../../components/section/SectionWrapper";

interface DescriptionI {
  content: string;
  defaultLines?: number;
  hideShowMore?: boolean;
  showTitle?: boolean;
  isLoading?: boolean;
}

const Description = (props: DescriptionI) => {
  const {
    content,
    defaultLines = 4,
    hideShowMore = false,
    showTitle = false,
    isLoading = false,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [numberOfLines, setNumberOfLines] = useState(defaultLines);

  const isHide = defaultLines === numberOfLines;

  useEffect(() => {
    if (!hideShowMore && !isHide) {
      setNumberOfLines(defaultLines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <SectionWrapper>
      {showTitle &&
        (isLoading ? (
          <Box pb={1}>
            <Skeleton variant="text" sx={{ width: 80, fontSize: 28 }} />
          </Box>
        ) : (
          <SectionTitleWithTranslation translationKey="common.description.title" />
        ))}
      {isLoading ? (
        <Typography>
          {Array.from(Array(numberOfLines).keys()).map((item) => (
            <Skeleton key={item} variant="text" style={{ width: "100%" }} />
          ))}
        </Typography>
      ) : (
        <Typography
          variant="body1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: hideShowMore ? "auto" : numberOfLines,
            WebkitBoxOrient: "vertical",
            whiteSpace: "break-spaces",
          }}
          dangerouslySetInnerHTML={{ __html: linkifyHtml(content) }}
        />
      )}

      {!hideShowMore && (
        <Box display="flex" justifyContent="flex-end">
          {isLoading ? (
            <Skeleton variant="text" style={{ width: 120, marginTop: 7 }} />
          ) : (
            <Button
              onClick={() => setNumberOfLines(isHide ? 1000 : defaultLines)}
              color="secondary"
            >
              {t(
                isHide
                  ? "city.description.readMore"
                  : "city.description.readHide"
              )}
            </Button>
          )}
        </Box>
      )}
    </SectionWrapper>
  );
};

export default Description;
