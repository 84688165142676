import styled from "@emotion/styled";
import { Box } from "@mui/material";

const CheckButtonSection = styled(Box)<any>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

export default CheckButtonSection;
