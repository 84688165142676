import { Box, Card, CardActionArea, Skeleton } from "@mui/material";
import { CityAvatarWidth, CityCardWidth } from "./CityCard";

const SkeletonCard = () => {
  return (
    <Box py={1}>
      <Card sx={{ width: CityCardWidth }}>
        <CardActionArea>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box
              py={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                width={CityAvatarWidth}
                height={CityAvatarWidth}
              />
            </Box>
            <Box pb={1} fontWeight="bold" fontSize={16}>
              <Skeleton variant="text" style={{ width: 90 }} />
            </Box>
            <Box pb={2}>
              <Skeleton variant="text" style={{ width: 110 }} />
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default SkeletonCard;
