import { useQuery } from "@tanstack/react-query";
import {
  GetRandomAttractions,
  GetRandomAttractionsQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";
import DashboardItems from "./DashboardItems";

const RandomAttractions = () => {
  const { data, isLoading } = useQuery([GetRandomAttractionsQuery], () => {
    return getMethod(GetRandomAttractions());
  });

  return (
    <DashboardItems
      items={data}
      isLoading={isLoading}
      title="Wylosowane dla Ciebie"
      getLinkTo={(item) =>
        `/attraction?id=${item.id}&name=${item.name}&cityId=${item.cityId}`
      }
    />
  );
};

export default RandomAttractions;
