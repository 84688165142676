import {
  Box,
  ImageList,
  ImageListItem,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useCallback } from "react";
import { imageUrl } from "../integration/httpService";
import ImageViewer from "react-simple-image-viewer";

const HeaderImages = ({ isLoading, images }: any) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const theme = useTheme();

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (isMobile) {
    return null;
  }
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        style={{
          height: 250,
        }}
      />
    );
  }
  return (
    <Box sx={{ position: "relative" }}>
      {images.length === 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          flex="1"
          onClick={() => openImageViewer(0)}
        >
          <img
            src={imageUrl(images[0].url)}
            alt={images[0].title}
            style={{
              objectFit: "cover",
              height: 250,
              cursor: "pointer",
            }}
            loading="lazy"
          />
        </Box>
      ) : (
        <>
          {/* <Box
            sx={{
              "background-size": "cover",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              //   width: "100%",
              color: "white",
              zIndex: 1,
              padding: "70px",
              "background-image":
                "linear-gradient(to bottom, rgb(25, 48, 76, 0.05), rgba(25, 48, 76, 0.95))",
            }}
          ></Box> */}
          <ImageList
            variant="masonry"
            rowHeight={250}
            cols={Math.min(images.length, 3)}
            gap={8}
          >
            {images.map((item: any, index: number) => (
              <ImageListItem
                key={item.img}
                onClick={() => openImageViewer(index)}
              >
                <img
                  src={imageUrl(item.url)}
                  alt={item.title}
                  loading="lazy"
                  style={{ cursor: "pointer" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </>
      )}
      {isViewerOpen && (
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 2500,
          }}
        >
          <ImageViewer
            src={images.map((image: any) => imageUrl(image.url))}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        </Box>
      )}
    </Box>
  );
};

export default HeaderImages;
