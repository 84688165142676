import { Box } from "@mui/material";
import AdditionalInfo from "./common/AdditionalInfo";
import BuyHere from "./common/BuyHere";
import ItemLabelWithValue from "./ItemLabelWithValue";
import SectionTitleWithTranslation from "./section/SectionTitleWithTranslation";
import SectionWrapper from "./section/SectionWrapper";
import isEmpty from "lodash/isEmpty";
import TicketsSkeleton from "./TicketsSkeleton";

interface TicketsI {
  ticket: any;
  isLoading?: boolean;
}

const Tickets = (props: TicketsI) => {
  const { isLoading, ticket } = props;

  if (isLoading) {
    return <TicketsSkeleton />;
  }

  if (
    !ticket ||
    (isEmpty(ticket.details) &&
      isEmpty(ticket.additionalInformation) &&
      isEmpty(ticket.url))
  ) {
    return null;
  }

  return (
    <SectionWrapper>
      <SectionTitleWithTranslation translationKey="common.tickets.title" />
      <Box display="flex" flexWrap="wrap">
        {ticket.details?.map((ticket: any) => (
          <Box
            bgcolor="white"
            mr={3}
            mb={2}
            p={1}
            sx={{ borderRadius: 2, minWidth: 200, boxShadow: 1 }}
          >
            <ItemLabelWithValue
              label={`${ticket.type.name}:`}
              value={`${ticket.prices?.[0].value} zł`}
            />
          </Box>
        ))}
      </Box>
      <AdditionalInfo additionalInformation={ticket.additionalInformation} />
      <BuyHere url={ticket.url} />
    </SectionWrapper>
  );
};
export default Tickets;
