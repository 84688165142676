import { Box, Skeleton } from "@mui/material";
import SectionWrapper from "./section/SectionWrapper";

interface TicketsSkeletonI {}

const TicketsSkeleton = (props: TicketsSkeletonI) => {
  return (
    <SectionWrapper>
      <Skeleton variant="text" sx={{ width: 80, fontSize: 28 }} />
      <Box pt={1} display="flex" flexWrap="wrap">
        {Array.from(Array(5).keys()).map((item) => (
          <Box
            bgcolor="white"
            mr={3}
            mb={2}
            p={1}
            sx={{ borderRadius: 2, minWidth: 200, boxShadow: 1 }}
          >
            <Skeleton variant="text" sx={{ width: 190, fontSize: 28 }} />
          </Box>
        ))}
      </Box>
    </SectionWrapper>
  );
};
export default TicketsSkeleton;
