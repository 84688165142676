import styled from "@emotion/styled";
import { Box } from "@mui/material";

const TitleMostPopular = styled(Box)<any>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontWeight: "lighter",
    textTransform: "uppercase",
    textAlign: "center",
  },
  [theme.breakpoints.up("md")]: {
    fontWeight: "lighter",
    textTransform: "uppercase",
    pr: theme.spacing(2),
  },
}));

export default TitleMostPopular;
