import { Box, Button, Link } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import RowToColumn from "../components/common/RowToColumn";
import { getMethod } from "../integration/httpService";
import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ConsentsInfo = () => {
  const [showInfo, setShowInfo] = useState(false);

  const consentInfo = useQuery(["ConsentsInfo"], () => {
    // TODO move to constant
    return getMethod("/info/policy");
  });

  useEffect(() => {
    const { data, isLoading } = consentInfo;
    const consentCookie = cookies.get("consent");

    if (
      !consentCookie ||
      (!isLoading &&
        (moment(data?.lastModifiedDate).unix() > consentCookie || !data))
    ) {
      setShowInfo(true);
    }
  }, [consentInfo]);

  if (!showInfo) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        paddingX: 5,
        paddingY: 2,
        zIndex: (theme) => 5000,
        boxShadow: 4,
      }}
    >
      <RowToColumn
        sx={{ gap: "16px", alignItems: { xs: "end", md: "center" } }}
        justifyContent="center"
      >
        <Box
          alignItems="center"
          sx={{
            backgroundColor: "white",
          }}
        >
          {/* TODO add translations */}
          Strona korzysta z plików cookies w celu optymalizacji działania oraz
          świadczenia usług na najwyższym poziomie. Dalsze korzystanie ze strony
          jest jednoznaczne ze zgodą na ich użycie.
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box
            href="https://www.tripplay.pl/polityka.pdf"
            target="_blank"
            component={Link}
            // color="white"
            display="block"
            pr={1}
            py={1}
            sx={{ whiteSpace: "nowrap", width: "fit-content" }}
          >
            Polityka prywatności
          </Box>
          <Button
            onClick={() => {
              cookies.set("consent", moment().unix());

              setShowInfo(false);
            }}
            variant="outlined"
            sx={{ width: "fit-content", height: "fit-content" }}
          >
            Zamknij
          </Button>
        </Box>
      </RowToColumn>
    </Box>
  );
};

export default ConsentsInfo;
