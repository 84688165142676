import { Box, Card, CardActionArea, Skeleton } from "@mui/material";
import CheckButtonSection from "../components/common/CheckButtonSection";
import DescriptionItemCard from "../components/common/itemCard/DescriptionItemCard";
import TextCardSkeleton from "../components/common/itemCard/TextCardSkeletonSkeleton";
import RowToColumn from "../components/common/RowToColumn";
import { SuggestedAttractionCardHeight } from "./SuggestedAttractionCard";

interface SuggestedAttractionCardSkeletonI {}
const SuggestedAttractionCardSkeleton = (
  props: SuggestedAttractionCardSkeletonI
) => {
  return (
    <Card
      sx={{
        minHeight: { md: 450, sx: "auto" },
        transition: "transform 0.15s ease-in-out",
        "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
      }}
    >
      <CardActionArea sx={{ height: "100%" }}>
        <Skeleton
          sx={{
            width: { xs: "100%", md: "100%" },
            height: { xs: "auto", md: 250 },
            margin: { xs: "0 auto", md: 0 },
          }}
          variant="rectangular"
        />
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          minHeight={SuggestedAttractionCardHeight - 30}
          sx={{ minWidth: 0, gap: { xs: 2, md: 0 } }}
        >
          <Box>
            <TextCardSkeleton isBig width={"60%"} />
            <TextCardSkeleton width={"50%"} />
          </Box>
          <Box>
            <DescriptionItemCard numberOfLines={6}>
              <TextCardSkeleton width={"100%"} />
              <TextCardSkeleton width={"100%"} />
              <TextCardSkeleton width={"100%"} />
              <TextCardSkeleton width={"100%"} />
              <TextCardSkeleton width={"100%"} />
              <TextCardSkeleton width={"100%"} />
            </DescriptionItemCard>
            <RowToColumn justifyContent="flex-end">
              <CheckButtonSection>
                <TextCardSkeleton isBig />
              </CheckButtonSection>
              <Box pr={2} />
              <CheckButtonSection>
                <TextCardSkeleton isBig />
              </CheckButtonSection>
            </RowToColumn>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default SuggestedAttractionCardSkeleton;
