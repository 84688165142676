import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Card as MuiCard,
  SxProps,
} from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../../integration/httpService";

interface CardI {
  image: any;
  children?: ReactNode[] | ReactNode;
  statusIndicator?: ReactNode;
  sx?: SxProps;
  linkTo: string;
}

const ImageAspectRatio = 9 / 16;

const Card = (props: CardI) => {
  const { image, children, linkTo, statusIndicator, sx } = props;
  return (
    <MuiCard
      sx={{
        position: "relative",
        transition: "transform 0.15s ease-in-out",
        "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
        ...sx,
      }}
    >
      <CardActionArea
        component={Link}
        to={linkTo}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 0,
            overflow: "hidden",
            paddingBottom: ImageAspectRatio * 100 + "%",
            position: "relative",
          }}
        >
          <CardMedia
            component="img"
            alt={image.title}
            // src="https://api.dev.tripplay.pl/api/v1/static/images/369"
            src={imageUrl(image.url)}
            sx={{
              position: "absolute",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 1,
            flex: 1,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {children}
        </CardContent>
        {statusIndicator}
      </CardActionArea>
    </MuiCard>
  );
};

export default Card;
