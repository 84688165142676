import styled from "@emotion/styled";
import { Box } from "@mui/material";

const TitleItemCard = styled(Box)<any>(
  ({ theme, isBig, fontWeight, fontSize }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontWeight: fontWeight ? fontWeight : "light",
    fontSize: fontSize ? fontSize : isBig ? 20 : 14,
  })
);

export default TitleItemCard;
