import { Box, Button, SxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import InfinityCarousel from "../common/InfinityCarousel";
import ResponsiveCarousel from "../common/ResponsiveCarousel";
import HeaderMostPopular from "./components/HeaderMostPopular";
import TitleMostPopular from "./components/TitleMostPopular";
import { MostPopularSkeleton } from "./skeleton/MostPopularSkeleton";

interface MostPopularI {
  title?: string;
  children?: any[];
  toAll: string;
  centerMode?: boolean;
  isCarousel?: boolean;
  mt?: number;
  isLoading?: boolean;
  cardSkeleton?: any;
  spaceInHeader?: boolean;
  justifyContentMd?: string;
  showCheckAll?: boolean;
  numberOfChildrenSkeleton?: number;
  sx?: SxProps;
  infinity?: boolean;
  infinityCarouselResponsive?: any;
}
export const MostPopular = ({
  title = "common.title.theMostPopular",
  children = [],
  toAll,
  infinityCarouselResponsive,
  mt = 0,
  isLoading = false,
  cardSkeleton = undefined,
  spaceInHeader = true,
  showCheckAll = true,
  numberOfChildrenSkeleton,
  sx,
  infinity = false,
}: MostPopularI) => {
  const { t } = useTranslation();

  if (isLoading && cardSkeleton) {
    return (
      <MostPopularSkeleton
        numberOfChildren={numberOfChildrenSkeleton}
        cardSkeleton={cardSkeleton}
        mt={mt}
        showCheckAll={showCheckAll}
        spaceInHeader={spaceInHeader}
      />
    );
  }

  if (!children || children.length === 0) {
    return null;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, ...sx }}>
      <HeaderMostPopular mt={mt} spaceInHeader={spaceInHeader}>
        <Typography>
          <TitleMostPopular>{t(title)}</TitleMostPopular>
        </Typography>
        {showCheckAll && (
          <Button
            sx={{ display: { xs: "none", md: "flex" } }}
            color="secondary"
            to={toAll}
            component={Link}
          >
            {t("common.checkAll")}
          </Button>
        )}
      </HeaderMostPopular>
      {infinity ? (
        <InfinityCarousel responsive={infinityCarouselResponsive} sx>
          {children}
        </InfinityCarousel>
      ) : (
        <ResponsiveCarousel>{children}</ResponsiveCarousel>
      )}
      {showCheckAll && (
        <Button
          sx={{ display: { xs: "flex", md: "none" } }}
          color="secondary"
          to={toAll}
          component={Link}
        >
          {t("common.checkAll")}
        </Button>
      )}
    </Box>
  );
};
