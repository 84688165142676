import FacebookIcon from "@mui/icons-material/Facebook";
import { IconButton } from "@mui/material";

const FacebookButton = () => {
  return (
    <IconButton
      size="large"
      onClick={() =>
        window.open("https://www.facebook.com/tripplay.official", "_blank")
      }
    >
      <FacebookIcon color="secondary" />
    </IconButton>
  );
};

export default FacebookButton;
