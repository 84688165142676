import { Box, Button, CardActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "../components/common/Card";
import DescriptionItemCard from "../components/common/itemCard/DescriptionItemCard";
import SubtitleItemCard from "../components/common/itemCard/SubtitleItemCard";
import TitleItemCard from "../components/common/itemCard/TitleItemCard";
interface SuggestedCardI {
  title: string;
  image: any;
  description: string;
  subtitle: string;
  cityId: number;
  showButtons?: boolean;
  showCityButton?: boolean;
  linkTo: string;
}
export const SuggestedAttractionCardHeight = 500;

const SuggestedCard = (props: SuggestedCardI) => {
  const {
    image,
    subtitle,
    description,
    title,
    cityId,
    linkTo,
    showButtons = false,
    showCityButton = false,
  } = props;
  const { t } = useTranslation();
  return (
    <Card image={image} linkTo={linkTo}>
      <Box display="flex" flexDirection="column" flex="1">
        <TitleItemCard isBig>{title}</TitleItemCard>
        <SubtitleItemCard>{subtitle}</SubtitleItemCard>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 2,
        }}
      >
        <DescriptionItemCard numberOfLines={2}>
          {description}
        </DescriptionItemCard>
        {showButtons && (
          <CardActions sx={{ justifyContent: "flex-end", gap: 1 }}>
            {showCityButton && (
              <Button
                component={Link}
                to={`/city/${cityId}`}
                variant="outlined"
              >
                {t("common.checkCity")}
              </Button>
            )}
            <Button
              variant="contained"
              onMouseDown={(event) => event.stopPropagation()}
            >
              {t("common.check")}
            </Button>
          </CardActions>
        )}
      </Box>
    </Card>
  );
};

export default SuggestedCard;
