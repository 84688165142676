import styled from "@emotion/styled";
import { Box } from "@mui/material";

const OwnRow = styled(Box)<any>(({ theme, sx }) => ({
  gap: theme.spacing(3),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-around",
    alignItems: "center",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "hidden",
    flexDirection: "row",
  },
  ...sx,
}));

export default OwnRow;
