import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import LocationCityIcon from "@mui/icons-material/LocationCity";

interface CityInProgressCircleI {
  size: number;
}

const CityInProgressCircle = ({ size }: CityInProgressCircleI) => (
  <Box
    sx={{
      width: size,
      height: size,
      background: grey[300],
      borderRadius: "50%",
      display: "flex",
    }}
  >
    <LocationCityIcon
      sx={{ fontSize: 70, margin: "auto", color: "secondary" }}
    />
  </Box>
);

export default CityInProgressCircle;
