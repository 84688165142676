import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import CopyButton from "../buttons/CopyButton";
import HeaderWrapper from "../../cities/components/header/HeaderWrapper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";

interface HeaderPageI {
  name?: string;
  translationKey?: string;
  hideCopyButton?: boolean;
  isLoading?: boolean;
  cityId?: number | undefined | null;
  isBack?: boolean;
}

const HeaderPage = (props: HeaderPageI) => {
  const {
    name,
    hideCopyButton = false,
    translationKey = "",
    isLoading = false,
    cityId = undefined,
    isBack = false,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column">
      {cityId && isBack && (
        <Box
          onClick={() => navigate(`/city/${cityId}`)}
          display="flex"
          flexDirection="row"
          textAlign="center"
          alignItems="center"
          color={grey[600]}
          fontSize={12}
          fontWeight="bold"
          sx={{
            width: "fit-content",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon fontSize="small" />
          {t("header.backToCity")}
        </Box>
      )}
      <HeaderWrapper>
        <Box
          sx={{ fontSize: "h4.fontSize" }}
          fontWeight="medium"
          color="secondary.main"
        >
          {isLoading ? (
            <Skeleton variant="text" style={{ width: 180, height: 60 }} />
          ) : (
            name || t(translationKey)
          )}
        </Box>

        {!hideCopyButton && <CopyButton />}
      </HeaderWrapper>
    </Box>
  );
};
export default HeaderPage;
