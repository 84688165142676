import { Box } from "@mui/material";
import CityMap from "../cities/map/CityMap";
import PageWrapper from "../components/common/PageWrapper";
import VerticalBanner from "../components/VerticalBanner";
import { useQueryParam, NumberParam } from "use-query-params";
import HeaderPage from "../components/common/HeaderPage";
import MapFilters from "../cities/map/MapFilters";
import ContentAds from "../adsense/ContentAds";
import { useState } from "react";

const MapPage = () => {
  const [cityId] = useQueryParam("cityId", NumberParam);
  const [filters, setFilters] = useState({});
  const callback = (fs: any) => setFilters(fs);

  return (
    <Box display="flex" flex="1">
      <PageWrapper direction="row">
        <Box
          sx={{
            flex: { xs: 1, md: 0.7 },
            gap: 3,
            display: "flex",
            flexDirection: "column",
            minWidth: "1px",
          }}
        >
          <HeaderPage
            isLoading={false}
            name={"Mapa"}
            cityId={cityId}
            isBack={true}
          />
          <MapFilters cityId={cityId} callback={callback} />
          <CityMap filters={filters} cityId={cityId} showButton={false} />
          <ContentAds />
        </Box>
        <VerticalBanner />
      </PageWrapper>
    </Box>
  );
};

export default MapPage;
