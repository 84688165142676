import { Box } from "@mui/material";

interface AddDateI {
  date: string;
}
const AddDate = ({ date }: AddDateI) => {
  return <Box>{date}</Box>;
};

export default AddDate;
