import { Avatar, Box, Card, CardActionArea } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router";
import { imageUrl } from "../../integration/httpService";
import CityInProgressCircle from "../common/CityInProgressCircle";

interface CityCardI {
  title: string;
  image?: any;
  id?: number;
  subtitle: string;
  isBuilding?: boolean;
  isActive?: boolean;
}
export const CityCardWidth = 180;
export const CityAvatarWidth = 110;

const CityCard = (props: CityCardI) => {
  const { title, image, subtitle, id, isBuilding, isActive } = props;
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: CityCardWidth,
        transition: "transform 0.15s ease-in-out",
        "&:hover": { transform: "scale3d(1.1, 1.1, 1)" },
        backgroundColor: isBuilding
          ? grey[50]
          : isActive
          ? "#72b6ffc4"
          : "white",
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CardActionArea
        disabled={isBuilding}
        onClick={() => navigate(`/city/${id}?name=${title}`)}
      >
        <Box display="flex" alignItems="center" flexDirection="column" py={2}>
          <Box
            py={2}
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {!isBuilding ? (
              <Avatar
                sx={{ width: CityAvatarWidth, height: CityAvatarWidth }}
                src={imageUrl(image.url)}
              />
            ) : (
              <CityInProgressCircle size={CityAvatarWidth} />
            )}
          </Box>
          <Box pb={1} fontWeight="bold" textAlign="center" fontSize={16}>
            {title}
          </Box>
          <Box pb={2}>{subtitle}</Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default CityCard;
