import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import AllElements from "../components/allElements/AllElementsPage";
import {
  GetCityProfileId,
  GetCityProfileIdQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";

const Tours = () => {
  const [cityId] = useQueryParam("cityId", NumberParam);

  const response = useQuery([GetCityProfileIdQuery, cityId], () => {
    return getMethod(GetCityProfileId(cityId));
  });

  const tours = response.isLoading ? [] : response.data.tours;

  return (
    <AllElements
      getLinkTo={(item) =>
        `/tour?id=${item.id}&name=${item.name}&cityId=${cityId}`
      }
      title="tours.title"
      backendPagination={false}
      isLoading={response.isLoading}
      backendData={tours}
      cityId={cityId}
    />
  );
};

export default Tours;
