import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ItemLabelWithValueI {
  icon?: any;
  label: string;
  value: string;
  minWidth?: number;
  isActive?: boolean;
  flexDirection?: any;
  alignItems?: any;
}

const ItemLabelWithValue = (props: ItemLabelWithValueI) => {
  const {
    icon,
    label,
    value,
    minWidth,
    isActive,
    flexDirection = "row",
    alignItems = "center",
  } = props;
  const { t } = useTranslation();
  if (!value) {
    return null;
  }
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection={flexDirection}
      py={1}
      alignItems={alignItems}
    >
      {icon}
      <Box
        fontWeight="bold"
        minWidth={minWidth || "auto"}
        color={isActive ? "secondary.main" : "primary.main"}
      >
        {t(label) || label}
      </Box>
      <Box pl={flexDirection === "row" ? 1 : 0}>{value}</Box>
    </Box>
  );
};

export default ItemLabelWithValue;
