import { IconButton } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const CopyButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    enqueueSnackbar(t("common.copy"), { variant: "info" });
  };

  return (
    <IconButton
      aria-label="delete"
      color="secondary"
      size="large"
      style={{ backgroundColor: "white" }}
      onClick={copy}
    >
      <ShareOutlinedIcon />
    </IconButton>
  );
};

export default CopyButton;
