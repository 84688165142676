import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import AllElements, {
  PageSize,
} from "../components/allElements/AllElementsPage";
import {
  GetEventsByCityId,
  GetEventsByCityIdQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";

const Events = () => {
  const [cityId] = useQueryParam("cityId", NumberParam);
  const [page] = useQueryParam("page", NumberParam);

  const response = useQuery([GetEventsByCityIdQuery, cityId, page], () => {
    return getMethod(GetEventsByCityId(cityId, page ? page - 1 : 0, PageSize));
  });

  return (
    <AllElements
      title="events.title"
      getLinkTo={(item) =>
        `/event?id=${item.id}&name=${item.name}&cityId=${cityId}`
      }
      backendPagination={true}
      numberOfPages={response?.data?.totalPages}
      isLoading={response.isLoading}
      backendData={response?.data?.content}
      cityId={cityId}
    />
  );
};

export default Events;
