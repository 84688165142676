import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

const ResponsiveCarousel = ({ children }: any) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  return (
    <ResponsiveCarouselBox {...events} ref={ref}>
      {children}
    </ResponsiveCarouselBox>
  );
};

export const ResponsiveCarouselBox = styled(Box)<any>(({ theme }) => ({
  justifyContent: "flex-start",
  gap: "20px",
  width: "100%",
  padding: `${theme.spacing(2)} 0`,
  margin: `-${theme.spacing(2)} 0`,
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.up("sm")]: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "auto",
    overflowY: "hidden",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "flex",
    },
  },
}));

export default ResponsiveCarousel;
