import { PageSize } from "../components/allElements/AllElementsPage";

const pagination = (page = 0, pageSize = PageSize) =>
  `?page=${page}&rowsPerPage=${pageSize}`;
export const GetCities = "/cities";
export const GetCitiesQuery = "GetCities";

export const GetBuildingCities = "/cities/building";
export const GetBuildingCitiesQuery = "GetBuildingCities";

export const GetCity = (id: any) => `/cities/${id}`;
export const GetCityQuery = "GetCity";

export const GetNews = (id: any) => `/news/${id}`;
export const GetNewsQuery = "GetNews";

export const GetNewsByCityId = (id: any, page?: number, pageSize?: number) =>
  `/news/city/${id}${pagination(page, pageSize)}`;
export const GetNewsByCityIdQuery = "GetNewsByCityId";

export const GetEventsByCityId = (id: any, page?: number, pageSize?: number) =>
  `/events/city/${id}${pagination(page, pageSize)}`;
export const GetEventsByCityIdQuery = "GetEventsByCityId";

export const GetCityProfileId = (id: any) => `/cities/${id}/portal`;
export const GetCityProfileIdQuery = "GetCityProfileIdQuery";

export const GetAttractionId = (id: number | any) => `/attractions/${id}`;
export const GetAttractionIdQuery = `GetAttractionIdQuery`;

export const GetSelectedAttractions = () => `/attractions/selected?maxRows=6`;
export const GetSelectedAttractionsQuery = `GetSelectedAttractionsQuery`;

export const GetRandomAttractions = () => `/attractions/random?maxRows=6`;
export const GetRandomAttractionsQuery = `GetRandomAttractionsQuery`;

export const GetEventId = (id: number | any) => `/events/${id}`;
export const GetEventIdQuery = "GetEventIdQuery";

export const GetTourWithMapById = (id: number | any) => `/tours/${id}/detail`;
export const GetTourWithMapByIdQuery = "GetTourWithMapById";

export const GetAppInfo = () => `/info?mobileVersion=1`;
export const GetAppInfoQuery = "GetAppInfo";
