import { Box } from "@mui/material";
import Grow from "@mui/material/Grow";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useMemo } from "react";

const InfinityCarousel = ({
  children,
  callback,
  autoplaySpeed = 5000,
  slidesToShow = 5,
  responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  whiteArrows,
}: any) => {
  const settings: Settings = useMemo(
    () => ({
      infinite: true,
      swipe: false,
      slidesToShow,
      slidesToScroll: 1,
      initialSlide: 1,
      waitForAnimate: false,
      autoplay: true,
      speed: 1000,
      autoplaySpeed,
      arrows: true,
      centerMode: true,
      pauseOnHover: true,
      adaptiveHeight: true,
      responsive,
      prevArrow: (
        <Arrow
          icon={<NavigateBeforeIcon />}
          whiteArrows={whiteArrows}
          sx={{ justifyContent: "flex-end" }}
        />
      ),
      nextArrow: (
        <Arrow
          icon={<NavigateNextIcon />}
          whiteArrows={whiteArrows}
          sx={{ justifyContent: "flex-start" }}
        />
      ),
      beforeChange: (current, next) => (callback ? callback(next) : null),
    }),
    [callback, autoplaySpeed, slidesToShow, responsive, whiteArrows]
  );

  return (
    <Box
      sx={{
        "& .slick-list": {
          margin: "0 -5px",
        },
        "& .slick-slice": {
          margin: "0 5px",
        },
        paddingBottom: "15px",
      }}
    >
      <Slider {...settings}>
        {children.map((child: any, index: number) => (
          <Grow
            key={index}
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            timeout={750}
          >
            <Box
              sx={{
                display: "flex !important",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {child}
            </Box>
          </Grow>
        ))}
      </Slider>
    </Box>
  );
};

export default InfinityCarousel;

function Arrow(props: any) {
  const { className, style, onClick, icon, whiteArrows, sx } = props;
  return (
    <Box
      className={className}
      style={{ ...style }}
      onClick={onClick}
      sx={{
        display: "flex !important",
        alignItems: "center",
        color: whiteArrows ? "white" : "black",
        "& svg": {
          fontSize: "50px",
          transition: "all 0.15s ease-in-out",
          transform: "scale3d(1, 1, 1)",
          borderRadius: "50%",
        },
        "&:hover svg": {
          color: "white",
          transform: "scale3d(1.25, 1.25, 1)",
          backgroundColor: "rgba(71, 71, 71, 0.5)",
        },
        ...sx,
      }}
    >
      {icon}
    </Box>
  );
}
