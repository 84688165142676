import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderWrapper from "./header/HeaderWrapper";
import CopyButton from "../../components/buttons/CopyButton";

interface CityHeaderI {
  name: string;
  isLoading?: boolean;
}

const CityHeader = (props: CityHeaderI) => {
  const { name, isLoading = false } = props;
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <Box display="flex" flexDirection="column">
        <Box sx={{ fontSize: "h6.fontSize" }} fontWeight="light">
          {t("city.header.welcome")}
        </Box>
        <Box
          sx={{ fontSize: "h4.fontSize" }}
          fontWeight="medium"
          color="secondary.main"
          // color="white"
        >
          {isLoading ? (
            <Skeleton variant="text" style={{ width: 180, height: 60 }} />
          ) : (
            name
          )}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        {/* HIDE - dont support yet */}
        {/* <Box pr={3}>
          <IconButton
            aria-label="delete"
            size="large"
            color="secondary"
            style={{ backgroundColor: "white" }}
          >
            <HomeOutlinedIcon />
          </IconButton>
        </Box> */}
        <CopyButton />
      </Box>
    </HeaderWrapper>
  );
};
export default CityHeader;
