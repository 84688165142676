import { Box, Card, CardActionArea, Skeleton } from "@mui/material";
import ColumnSpace from "../OwnColumn";
import DescriptionItemCard from "./DescriptionItemCard";
import { ImageItemCardHeight, ItemCardWidth } from "./ItemCard";
import TitleItemCard from "./TitleItemCard";

const ItemCardSkeleton = () => {
  return (
    <Card sx={{ maxWidth: ItemCardWidth }}>
      <CardActionArea>
        <Box display="flex" flex="1" flexDirection="column">
          <Skeleton
            variant="rectangular"
            sx={{ height: ImageItemCardHeight }}
            style={{ width: "100%" }}
          />
          <ColumnSpace sx={{ p: 2, gap: 1 }}>
            <TitleItemCard>
              <Skeleton variant="text" style={{ width: ItemCardWidth - 90 }} />
              <Skeleton variant="text" style={{ width: ItemCardWidth - 120 }} />
            </TitleItemCard>
            <DescriptionItemCard>
              <Skeleton variant="text" style={{ width: ItemCardWidth - 40 }} />
              <Skeleton variant="text" style={{ width: ItemCardWidth - 30 }} />
              <Skeleton variant="text" style={{ width: ItemCardWidth - 60 }} />
              <Skeleton variant="text" style={{ width: ItemCardWidth - 40 }} />
            </DescriptionItemCard>
          </ColumnSpace>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ItemCardSkeleton;
