import styled from "@emotion/styled";
import { Box, SxProps } from "@mui/material";

export const PulseDot = ({ sx }: { sx?: SxProps }) => (
  <Keyframes sx={sx}>
    <Box
      sx={{
        position: "absolute",
        zIndex: 1000,
        background: "green",
        borderRadius: "50%",
        width: 10,
        height: 10,
        transform: "translate(-50%, -50%)",
      }}
    />
  </Keyframes>
);

const Keyframes = styled(Box)(({ sx }: any) => ({
  "@keyframes pulsate": {
    from: {
      opacity: 1,
      transform: "scale(1)",
    },
    to: {
      opacity: 0,
      transform: "scale(2)",
    },
  },
  animation: "pulsate 1s infinite ease",
  position: "absolute",
  ...sx,
}));
