import AlarmIcon from "@mui/icons-material/Alarm";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import ContentAds from "../adsense/ContentAds";
import { DefaultContentHeight } from "../articles/Article";
import Description from "../cities/components/Description";
import HeaderImages from "../components/HeaderImages";
import ImagesCarousel from "../components/ImagesCarousel";
import ItemLabelWithValue from "../components/ItemLabelWithValue";
import Tickets from "../components/Tickets";
import VerticalBanner from "../components/VerticalBanner";
import HeaderPage from "../components/common/HeaderPage";
import PageWrapper from "../components/common/PageWrapper";
import ItemCard from "../components/common/itemCard/ItemCard";
import ItemCardSkeleton from "../components/common/itemCard/ItemCardSkeleton";
import { MostPopular } from "../components/mostPopular/MostPopular";
import { GetEventId, GetEventIdQuery } from "../integration/apiRoutes";
import { useProfileRequest } from "../integration/httpHooks";
import { getMethod } from "../integration/httpService";
import HelmetHeader from "../utilis/HelmetHeader";

const Event = () => {
  const [eventId] = useQueryParam("id", NumberParam);
  const [cityId] = useQueryParam("cityId", NumberParam);

  const response = useQuery([GetEventIdQuery, eventId], () => {
    return getMethod(GetEventId(eventId));
  });
  const profileResponse = useProfileRequest(cityId as number);

  const isLoading = response.isLoading || profileResponse.isLoading;

  const {
    images,
    name,
    description,
    address,
    url,
    ticket,
    startDate,
    endDate,
  } = isLoading
    ? {
        images: [],
        name: "",
        description: "",
        address: "",
        url: "",
        ticket: "",
        startDate: "",
        endDate: "",
      }
    : response.data;
  return (
    <Box display="flex" flex="1">
      <HelmetHeader
        isLoading={isLoading}
        title={name}
        description={description}
      />
      <PageWrapper direction="row">
        <Box sx={{ flex: { xs: "1", md: "0.7" }, minWidth: "1px" }}>
          <Box
            sx={{ minHeight: { xs: "auto", md: DefaultContentHeight } }}
            pb={2}
          >
            <HeaderPage
              isLoading={isLoading}
              name={name}
              cityId={cityId}
              isBack={true}
            />
            <ItemLabelWithValue
              label="common.address"
              value={address}
              icon={<PlaceIcon color="secondary" />}
            />
            <HeaderImages isLoading={isLoading} images={images} />

            <Box>
              {/* Ecstract to new component */}

              <ItemLabelWithValue
                label="common.wwwPage"
                value={url}
                icon={<LanguageIcon color="secondary" />}
              />
              <ItemLabelWithValue
                label="common.startDate"
                value={startDate}
                icon={<AlarmIcon color="secondary" />}
              />
              <ItemLabelWithValue
                label="common.endDate"
                value={endDate}
                icon={<AlarmIcon color="secondary" />}
              />
            </Box>
            <Box sx={{ display: { md: "none" } }}>
              <ImagesCarousel images={images} />
            </Box>
            <Description isLoading={isLoading} content={description} />
            <Tickets isLoading={isLoading} ticket={ticket} />
          </Box>
          <MostPopular
            children={profileResponse.data?.events
              .filter((event: any) => event.id !== eventId)
              ?.slice(0, 10)
              ?.map((event: any) => (
                <ItemCard
                  key={event.id}
                  linkTo={`/event?id=${event.id}&cityId=${cityId}`}
                  title={event.name}
                  description={event.description}
                  image={event.mainImage}
                />
              ))}
            sx={{ mb: 2, mt: 2 }}
            isCarousel={false}
            isLoading={isLoading}
            cardSkeleton={<ItemCardSkeleton />}
            numberOfChildrenSkeleton={3}
            title={"city.events.title"}
            toAll={`/events?cityId=${cityId}&page=1`}
            spaceInHeader={false}
          />
          <ContentAds />
        </Box>
        <VerticalBanner />
      </PageWrapper>
    </Box>
  );
};

export default Event;
