import styled from "@emotion/styled";
import { Box } from "@mui/material";

const HeaderMostPopular = styled(Box)<any>(
  ({ theme, mt, spaceInHeader = true }) => ({
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      mt: mt,
      mb: 2,
      display: "flex",
      flexDirection: "row",
      justifyContent: spaceInHeader ? "space-between" : "flex-start",
      alignItems: "center",
    },
  })
);

export default HeaderMostPopular;
