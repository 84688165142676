import axios from "axios";

const ApiVersion = "1";
const Server = "https://api.tripplay.pl";

export const httpService = axios.create({
  baseURL: `${Server}/api/v${ApiVersion}/m`,
});

export const imageUrl = (url: string, type: string = "") => {
  return Server.concat(`/api/v${ApiVersion}`).concat(url).concat(type);
};

export const getMethod = (url: any) =>
  httpService.get(url).then((response) => response.data.data);
