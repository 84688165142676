import { Box, Container, Grid, Link } from "@mui/material";

import FacebookButton from "../components/buttons/FacebookButton";
import InstagramButton from "../components/buttons/InstagramButton";
const Footer = () => {
  return (
    <footer style={{ marginTop: "auto" }}>
      <Box
        p={{ xs: 5, sm: 5 }}
        bgcolor="primary.main"
        color="white"
        borderTop={1}
      >
        <Container>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={3}>
                <Box fontWeight="bold" pb={1}>
                  Współpraca
                </Box>
                <Box
                  color="white"
                  href="https://www.tripplay.pl/prezentacja.pdf"
                  target="_blank"
                  component={Link}
                  display="block"
                >
                  Samorządy
                </Box>
                <Box
                  href="https://www.tripplay.pl/polityka.pdf"
                  target="_blank"
                  component={Link}
                  color="white"
                  display="block"
                >
                  Polityka prywatności
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Box fontWeight="bold" pb={1}>
                  Kontakt
                </Box>
                <Box
                  color="white"
                  href='mailto:kontakt@tripplay.pl?subject="Tripplay - kontakt ze strony internetowej."'
                  component={Link}
                >
                  {/* <a href='mailto:kontakt@tripplay.pl?subject="Tripplay - kontakt ze strony internetowej."'> */}
                  Email: kontakt@tripplay.pl
                  {/* </a> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Box fontWeight="bold" pb={1}>
                  Informacje
                </Box>
                <Box>Tripplay Sp. z o.o.</Box>
                <Box>Nip: 5252823809</Box>
                <Box>00-020 Warszawa</Box>
                <Box>ul. Chmielna 2/31</Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box fontWeight="bold" pb={1}>
                  Pobierz naszą aplikację!
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    flexDirection: { xs: "row", md: "column" },
                  }}
                >
                  <Box
                    component="a"
                    target="blank"
                    href="https://apps.apple.com/us/app/tripplay/id1534939032?itsct=apps_box_badge&amp;itscg=30200"
                  >
                    <Box
                      component="img"
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pl-pl?size=250x83&amp;releaseDate=1616630400&h=182ee123519e75a1fbb8c1986733367c"
                      alt="Pobierz z App Store"
                      sx={{
                        width: "180px",
                      }}
                    />
                  </Box>
                  <Box
                    component="a"
                    target="blank"
                    href="https://play.google.com/store/apps/details?id=pl.tripplay&hl=pl&gl=PL&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  >
                    <Box
                      component="img"
                      alt="Pobierz z Google Play"
                      sx={{
                        marginTop: "-9px",
                        width: { xs: "205px", md: "180px" },
                      }}
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
            borderTop={1}
            pt={2}
            mt={2}
          >
            <Box display="flex" flex="0.33">
              {/* TODO add translation */}
              @2023 Tripplay Wszystkie prawa zastrzeżone
            </Box>
            <Box display="flex" flex="0.33" justifyContent="center">
              <FacebookButton />
              <InstagramButton />
            </Box>
            <Box
              flex="0.33"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Box>PL tripplay</Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
