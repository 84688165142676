import { useTranslation } from "react-i18next";
import SectionTitle from "./SectionTitle";

interface SectionTitleWithTranslationI {
  translationKey: string;
}
const SectionTitleWithTranslation = (props: SectionTitleWithTranslationI) => {
  const { translationKey } = props;
  const { t } = useTranslation();

  return <SectionTitle>{t(translationKey)}</SectionTitle>;
};

export default SectionTitleWithTranslation;
