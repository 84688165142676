import "./App.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import { Box, Container, createTheme, ThemeProvider } from "@mui/material";
import Cities from "./cities/Cities";
import City from "./cities/City";
import Navbar from "./navbar/Navbar";
import Footer from "./navbar/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Attraction from "./attractions/Attraction";
import { SnackbarProvider } from "notistack";
import Attractions from "./attractions/Attractions";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import Tours from "./tours/Tours";
import Tour from "./tours/Tour";
import Events from "./events/Events";
import Event from "./events/Event";
import Articles from "./articles/Articles";
import Article from "./articles/Article";
import MapPage from "./map/MapPage";
import ConsentsInfo from "./consents/ConsentsInfo";
import ScrollToTop from "./utilis/ScrollToTop";

import ReactGA from "react-ga4";

const TRACKING_ID = "G-7V11LGM2JD"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const theme = createTheme({
  palette: {
    primary: {
      main: "#19304c",
      // light: LightSkyblue,
    },
    secondary: {
      main: "#4895e8",
    },
    text: {
      primary: "#19304c",
      secondary: "#19304c",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        color: "primary",
      },
    },
  },
});

const queryClient = new QueryClient({
  // queryCache: new QueryCache({
  //   onError: (error) => console.log("Test"),
  // }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.send("pageview");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search]);

  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Box
              minHeight="100vh"
              display="flex"
              flexDirection="column"
              bgcolor="#f7f9ff"
            >
              <Navbar />
              <Routes>
                <Route path="/" element={<Dashboard />} />
              </Routes>
              <Container maxWidth="xl">
                <ScrollToTop />
                <Routes>
                  {/* <Container disableGutters> */}
                  <Route path="/cities" element={<Cities />} />
                  <Route path="/city/:cityId" element={<City />} />
                  <Route
                    path="/attraction/:attractionId"
                    element={<Attraction />}
                  />

                  <Route path="/tours" element={<Tours />} />
                  <Route path="/tour" element={<Tour />} />
                  <Route path="/attractions" element={<Attractions />} />
                  <Route path="/attraction" element={<Attraction />} />

                  <Route path="/events" element={<Events />} />
                  <Route path="/event" element={<Event />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/article" element={<Article />} />
                  <Route path="/map" element={<MapPage />} />

                  {/* </Container> */}
                </Routes>
              </Container>
              <ConsentsInfo />
              <Footer />
            </Box>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryParamProvider>
    </QueryClientProvider>
  );
}

export default App;
