import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import SuggestedAttractionCardSkeleton from "./SuggestedAttractionCardSkeleton";
import SuggestedAttractionsGrid from "./SuggestedAttractionsGrid";
import SuggestedCard from "./SuggestedCard";

interface DashboardItemsI {
  items: any[];
  isLoading: boolean;
  title: string;
  getLinkTo: (item: any) => string;
}

const DashboardItems = (props: DashboardItemsI) => {
  const { items, isLoading, title, getLinkTo } = props;

  if (!isLoading && isEmpty(items)) {
    return null;
  }
  return (
    <Box>
      <Box
        sx={{
          fontWeight: "lighter",
          textAlign: "center",
          fontSize: 48,
        }}
      >
        {title}
      </Box>
      <SuggestedAttractionsGrid>
        {isLoading ? (
          <>
            <SuggestedAttractionCardSkeleton />
            <SuggestedAttractionCardSkeleton />
            <SuggestedAttractionCardSkeleton />
            <SuggestedAttractionCardSkeleton />
          </>
        ) : (
          items?.map((att: any) => {
            return (
              <SuggestedCard
                key={att.id}
                linkTo={getLinkTo(att)}
                image={att.mainImage}
                subtitle={att.address}
                title={att.name}
                description={att.description}
                cityId={att.cityId}
                showButtons={true}
                showCityButton
              />
            );
          })
        )}
      </SuggestedAttractionsGrid>
    </Box>
  );
};

export default DashboardItems;
