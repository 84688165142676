import { differenceInCalendarDays, format, parseISO } from "date-fns";
import { hasEventStarted } from "./HasEventStarted";

// TODO add translation
export const getEventTimeLabel = (startDate: string) => {
  const label = format(parseISO(startDate), "dd.MM.yy HH:mm");
  if (hasEventStarted(startDate)) {
    return `Trwa | ${label}`;
  }
  const monthDaysLeft = differenceInCalendarDays(
    parseISO(startDate),
    new Date()
  );
  if (monthDaysLeft < 7 && monthDaysLeft > 1) {
    return `Za ${monthDaysLeft} dni | ${label}`;
  } else if (monthDaysLeft === 1) {
    return `Jutro | ${label}`;
  } else if (monthDaysLeft === 0) {
    return `Dzisiaj | ${label}`;
  }
  return label;
};
