import { Box, Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import Description from "../cities/components/Description";
import HeaderPage from "../components/common/HeaderPage";
import PageWrapper from "../components/common/PageWrapper";
import RowToColumn from "../components/common/RowToColumn";
import ImagesCarousel from "../components/ImagesCarousel";
import ItemLabelWithValue from "../components/ItemLabelWithValue";
import SectionTitleWithTranslation from "../components/section/SectionTitleWithTranslation";
import SectionWrapper from "../components/section/SectionWrapper";
import {
  GetTourWithMapById,
  GetTourWithMapByIdQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";

import ContentAds from "../adsense/ContentAds";
import HeaderImages from "../components/HeaderImages";
import VerticalBanner from "../components/VerticalBanner";
import HelmetHeader from "../utilis/HelmetHeader";
import { parseDistance, parseDuration } from "../utilis/Tools";
import AttractionsSteps from "./AttractionsSteps";

const Tour = () => {
  const [tourId] = useQueryParam("id", NumberParam);

  const { isLoading, data } = useQuery(
    [GetTourWithMapByIdQuery, tourId],
    () => {
      return getMethod(GetTourWithMapById(tourId));
    }
  );

  const {
    name,
    description,
    mainImage,
    distance,
    duration,
    attractions,
    cityId,
    types,
  } = isLoading
    ? {
        name: "",
        description: "",
        mainImage: undefined,
        distance: undefined,
        duration: undefined,
        attractions: [],
        cityId: "",
        types: [],
      }
    : data;
  return (
    <Box display="flex" flex="1">
      <HelmetHeader
        isLoading={isLoading}
        title={name}
        description={description}
      />
      <PageWrapper direction="row">
        <Box sx={{ flex: { xs: "1", md: "0.7" } }}>
          <HeaderPage
            isLoading={isLoading}
            name={name}
            cityId={cityId}
            isBack={true}
          />
          <HeaderImages isLoading={isLoading} images={[mainImage]} />
          <Box sx={{ display: { md: "none" } }}>
            <ImagesCarousel image={mainImage} />
          </Box>

          <Box
            flexDirection="row"
            justifyContent="center"
            flexWrap="wrap"
            display="flex"
            pt={0.3}
          >
            {types.map((type: any) => (
              <Box key={type.code} pr={1}>
                <Chip label={type.name} color="primary" size="small" />
              </Box>
            ))}
          </Box>

          <Description isLoading={isLoading} content={description} />

          <SectionWrapper>
            <SectionTitleWithTranslation translationKey="tour.details.title" />
            <RowToColumn>
              <ItemLabelWithValue
                label="tour.details.distance"
                value={parseDistance(distance)}
              />
              <ItemLabelWithValue
                label="tour.details.duration"
                value={parseDuration(duration)}
              />
              <ItemLabelWithValue
                label="tour.details.numberOfAttractions"
                value={attractions?.length}
              />
            </RowToColumn>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitleWithTranslation translationKey="tour.attractions.title" />
            <AttractionsSteps
              isLoading={isLoading}
              attractions={attractions}
              cityId={cityId}
            />
          </SectionWrapper>
          <ContentAds />
        </Box>
        <VerticalBanner />
      </PageWrapper>
    </Box>
  );
};

export default Tour;
