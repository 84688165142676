import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { getEventTimeLabel } from "../../../utilis/GetEventTimeLabel";

const DateItemCardBox = styled(Box)<any>(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  // fontWeight: "bold",
  fontSize: 13,
  marginBottom: "8px",
}));

interface DateItemCardI {
  startDate: string;
}

const DateItemCard = ({ startDate }: DateItemCardI) => {
  const dateLabel = useMemo(() => getEventTimeLabel(startDate), [startDate]);
  return <DateItemCardBox>{dateLabel}</DateItemCardBox>;
};

export default DateItemCard;
