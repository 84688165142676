import styled from "@emotion/styled";
import { Box } from "@mui/material";

const SectionTitle = styled(Box)<any>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontWeight: "lighter",
    textTransform: "uppercase",
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    paddingBottom: theme.spacing(2),
    fontWeight: "lighter",
    textTransform: "uppercase",
  },
}));

export default SectionTitle;
