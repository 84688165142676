import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
// import { TileLayer, icon, Marker, Popup, TileLayer } from "leaflet";
import { icon } from "leaflet";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useNavigate } from "react-router-dom";
import ImageCard from "../../components/common/ImageCard";
import { useProfileRequest } from "../../integration/httpHooks";
import { imageUrl } from "../../integration/httpService";
import {
  AttractionType,
  AttractionTypeColor,
  EventType,
  EventTypeColor,
  TourType,
  TourTypeColor,
} from "./TypesOfMarkers";

const iconSize = 32;
const colors = [
  "#e76872",
  "#b2e768",
  "#68e7dc",
  "#9d68e7",
  "#dd68e7",
  "#e768b2",
  "#6873e7",
  "#e79d68",
  "#e7dc68",
];

const CityMap = ({ showButton = true, cityId, filters }: any) => {
  const mapRef = useRef();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    data = {},
    isLoading,
    isFetched,
  } = useProfileRequest(cityId as string);
  const MarkerKey = (key: string, type: string) => `${type}_${key}`;

  const cleanDataForRender: any = useMemo(() => {
    if (!isLoading && data) {
      const events = filters?.events !== false ? data?.events : [] || [];
      const attractions =
        filters?.attractions !== false ? data?.attractions : [] || [];
      const tours = filters?.tours !== false ? data?.tours : [] || [];

      const dataWithDuplicateLocation = [
        ...attractions.map((a: any) =>
          Object({
            type: AttractionType,
            pointCount: 1,
            item: a,
            location: a.location,
            id: MarkerKey(a.id, AttractionType),
            borderColor: AttractionTypeColor,
            // icon: BusinessOutline,
            mainImage: a.mainImage,
            linkTo: `/attraction?id=${a.id}&cityId=${cityId}`,
          })
        ),
        ...events.map((a: any) =>
          Object({
            type: EventType,
            pointCount: 1,
            item: a,
            location: a.location,
            id: MarkerKey(a.id, EventType),
            borderColor: EventTypeColor,
            mainImage: a.mainImage,
            linkTo: `/event?id=${a.id}&cityId=${cityId}`,
          })
        ),
        ...tours.map((t: any) => {
          const legs = t.legs || [];
          const steps = legs[Math.floor(legs.length / 2)];

          return Object({
            type: TourType,
            pointCount: 1,
            item: t,
            location:
              steps.tourSteps[Math.floor(steps.tourSteps.length / 2)]
                .startLocation,
            id: MarkerKey(t.id, TourType),
            borderColor: TourTypeColor,
            // icon: TrailSignOutline,
            mainImage: t.mainImage,
            linkTo: `/tour?id=${t.id}&cityId=${cityId}`,
          });
        }),
      ];
      return dataWithDuplicateLocation;
    } else {
      return [];
    }
  }, [isLoading, filters, data, cityId]);

  const toursToDisplay: any = useMemo(() => {
    if (!isLoading && data?.tours && filters?.tours !== false) {
      return data?.tours.map((tour: any) => {
        const path = tour.legs.flatMap((leg: any) =>
          leg.tourSteps.flatMap((s: any) => [
            [s.startLocation.latitude, s.startLocation.longitude],
            [s.stopLocation.latitude, s.stopLocation.longitude],
          ])
        );

        return {
          // @ts-ignore
          path: _.uniqWith(path, _.isEqual),
          id: MarkerKey(tour.id, TourType),
        };
      });
    } else {
      return [];
    }
  }, [isLoading, filters?.tours, data]);

  useEffect(() => {
    if (data && cleanDataForRender && mapRef && isFetched) {
      const test = cleanDataForRender.map((item: any) => [
        item.location.latitude,
        item.location.longitude,
      ]);

      if (!_.isEmpty(test)) {
        // @ts-ignore
        mapRef?.current?.fitBounds(test);
      }
    }
  }, [mapRef, cleanDataForRender, data, isFetched]);

  return (
    <Box>
      <Box sx={{ boxShadow: "0px 0px 20px rgb(128 175 225 / 0.9)" }} id="map">
        {isLoading && (
          <Box position="relative">
            <Box
              position="absolute"
              style={{
                height: 500,
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: 2500,
                background: "rgb(128 175 225 / 0.65)",
              }}
            >
              {/* <SkeletonCity /> */}
            </Box>
          </Box>
        )}
        <MapContainer
          center={[52.0689163366494, 19.47961577361076]}
          style={{ height: 500 }}
          zoom={6}
          // @ts-ignore
          ref={mapRef}
          boundsOptions={{ padding: [25, 25] }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            chunkedLoading
            showCoverageOnHover={false}
            maxClusterRadius={10}
          >
            {toursToDisplay?.map((tour: any, index: number) => {
              const item = cleanDataForRender?.find(
                (item: any) => item.id === tour.id
              );

              return (
                <Polyline
                  key={tour.id}
                  pathOptions={{
                    color: colors[index],
                    weight: 8,
                    lineJoin: "round",
                    lineCap: "round",
                    stroke: true,
                    // fillColor: "red",
                    // fill: true,
                  }}
                  positions={tour.path}
                  // eventHandlers={{
                  //   mouseover: (event) => event.target.openPopup(),
                  // }}
                >
                  <Popup className="request-popup">
                    {item && (
                      <ImageCard
                        key={item.id}
                        linkTo={item.linkTo}
                        width={260}
                        title={item?.item?.name}
                        subtitle={item?.item?.description}
                        image={item.mainImage}
                      />
                    )}
                  </Popup>
                </Polyline>
              );
            })}
            {cleanDataForRender.map((item: any) => {
              if (!item.location?.latitude || !item.location?.longitude) {
                return null;
              }
              const Icon = icon({
                iconUrl: imageUrl(item.mainImage?.url, "/ICON"),
                iconSize: [iconSize, iconSize],
                iconAnchor: [iconSize / 2 + 2, iconSize / 2 + 2],
                popupAnchor: [0, -(iconSize / 2 + 4)],
                className: "marker-icon",
              });
              return (
                <Marker
                  icon={Icon}
                  key={item.id}
                  position={[item.location.latitude, item.location.longitude]}
                  eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                  }}
                >
                  <Popup className="request-popup">
                    <ImageCard
                      key={item.id}
                      linkTo={item.linkTo}
                      width={260}
                      title={item?.item?.name}
                      subtitle={item?.item?.description}
                      image={item.mainImage}
                    />
                  </Popup>
                </Marker>
              );
            })}
          </MarkerClusterGroup>
        </MapContainer>
        {/* )} */}
      </Box>
      {showButton && (
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          p={2}
          pb={0}
        >
          <Button
            variant="contained"
            size={isMobile ? "medium" : "large"}
            onClick={() => {
              navigate(`/map?cityId=${cityId}`);
            }}
          >
            Pokaż mapę!
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CityMap;
