import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ItemLabelWithValue from "../ItemLabelWithValue";

interface SectionBoxI {
  value: string;
  label: string;
  isActive?: boolean;
  isOpen?: boolean;
}

const SectionBox = (props: SectionBoxI) => {
  const { label, value, isActive, isOpen = true } = props;
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="white"
      mr={3}
      mb={2}
      p={1}
      sx={{ borderRadius: 2, minWidth: 200, boxShadow: 1 }}
    >
      <ItemLabelWithValue
        label={label}
        value={isOpen ? value : t("openHours.close")}
        isActive={isActive}
      />
    </Box>
  );
};

export default SectionBox;
