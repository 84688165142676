import styled from "@emotion/styled";
import { Box } from "@mui/material";

const OwnColumn = styled(Box)<any>(({ theme, height }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1",
  justifyContent: "space-between",
  height: height || "auto",
}));

export default OwnColumn;
