import SettingsIcon from "@mui/icons-material/Settings";
import { Autocomplete, Grow, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchBackground from "../images/searchBackground.jpg";
import {
  GetBuildingCities,
  GetBuildingCitiesQuery,
  GetCities,
  GetCitiesQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";

const styles = {
  paperContainer: {
    backgroundImage: `url(${SearchBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  position: "relative",
};

const Search = () => {
  const { data: dataCities } = useQuery([GetCitiesQuery], () =>
    getMethod(GetCities)
  );
  const { data: dataBuildingCities } = useQuery([GetBuildingCitiesQuery], () =>
    getMethod(GetBuildingCities)
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const options = useMemo(() => {
    const citiesOptions = dataCities?.map((city: any) => ({
      label: city.name,
      value: city.id,
    }));
    const buildingCitiesOptions = dataBuildingCities?.map((city: any) => ({
      label: city.name,
      value: city.id,
      disabled: true,
    }));
    return (
      citiesOptions &&
      buildingCitiesOptions && [...citiesOptions, ...buildingCitiesOptions]
    );
  }, [dataCities, dataBuildingCities]);
  return (
    <Box style={styles.paperContainer}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py={8}
      >
        <Grow in={true} style={{ transformOrigin: "50% 50% 0" }} timeout={1000}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" px={1}>
              <Box fontWeight="fontWeightBold" textAlign="center" color="white">
                {t("search.title")}
              </Box>
            </Typography>
            <Box py={5}>
              <Autocomplete
                id="searchCity"
                options={options}
                autoHighlight
                //TODO add translations
                noOptionsText="Nie znaleziono miasta"
                onChange={(_, selectedCity: any) => {
                  if (selectedCity?.value) {
                    navigate(`/city/${selectedCity.value}`);
                  }
                }}
                getOptionDisabled={(option) => option.disabled}
                sx={{ width: { xs: 300, md: 600 } }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.label}
                    {option.disabled && <SettingsIcon sx={{ ml: 1 }} />}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="primary"
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "rgba(255, 255, 255, 0.9)",
                        cursor: "pointer",
                      },
                      "& .MuiInputBase-input": {
                        cursor: "pointer",
                      },

                      boxShadow: "0 0px 10px rgb(255 255 255 / 0.9)",
                    }}
                    placeholder={t("search.placeHolder")}
                  />
                )}
              />
            </Box>
            {/* <Button
          style={{ color: "white", borderColor: "white" }}
          variant="outlined"
        >
          {t("search.showMap")}
        </Button> */}
          </Box>
        </Grow>
      </Box>
    </Box>
  );
};

export default Search;
