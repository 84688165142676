import { Helmet } from "react-helmet";

interface HelmetHeaderI {
  title: string;
  description: string;
  isLoading?: boolean;
}

const HelmetHeader = ({ title, description, isLoading }: HelmetHeaderI) => {
  if (isLoading) {
    return null;
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description?.slice(0, 155)} />
    </Helmet>
  );
};
export default HelmetHeader;
