import styled from "@emotion/styled";
import { Box } from "@mui/material";

const RowToColumn = styled(Box)<any>(({ theme, justifyContent, sx }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: justifyContent || "flex-start",
  },
  sx,
}));

export default RowToColumn;
