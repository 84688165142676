import { Box } from "@mui/material";
import AdditionalInfo from "./common/AdditionalInfo";
import SectionBox from "./section/SectionBox";
import SectionTitleWithTranslation from "./section/SectionTitleWithTranslation";
import SectionWrapper from "./section/SectionWrapper";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import TicketsSkeleton from "./TicketsSkeleton";

interface OpenHoursI {
  openTime: any;
  isLoading: boolean;
}
const sorter = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

const OpenHours = (props: OpenHoursI) => {
  const {
    openTime: { days, additionalInformation },
    isLoading,
  } = props;

  if (isLoading) {
    return <TicketsSkeleton />;
  }

  if (isEmpty(days) && isEmpty(additionalInformation)) {
    return null;
  }
  return (
    <SectionWrapper>
      <SectionTitleWithTranslation translationKey="common.openHours.title" />
      <Box display="flex" flexWrap="wrap">
        {days
          .sort(function sortByDay(a: any, b: any) {
            // @ts-ignore
            return sorter[a.day] - sorter[b.day];
          })
          .map((day: any) => (
            <SectionBox
              key={day.day}
              isOpen={day.isOpen}
              label={day.day}
              // @ts-ignore
              isActive={sorter[day.day] === moment().day()}
              value={`${day.open}-${day.close}`}
            />
          ))}
      </Box>
      <AdditionalInfo additionalInformation={additionalInformation} />
    </SectionWrapper>
  );
};
export default OpenHours;
