import styled from "@emotion/styled";
import { Box } from "@mui/material";

const DescriptionItemCard = styled(Box)<any>(
  ({ theme, numberOfLines = 3, isBig, mt }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: numberOfLines,
    WebkitBoxOrient: "vertical",
    fontSize: isBig ? 16 : 14,
    // textAlign: "justify",
    marginTop: mt,
  })
);

export default DescriptionItemCard;
