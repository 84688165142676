import { useQuery } from "@tanstack/react-query";
import {
  GetSelectedAttractions,
  GetSelectedAttractionsQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";
import DashboardItems from "./DashboardItems";

const SuggestedAttractions = () => {
  const { data, isLoading } = useQuery([GetSelectedAttractionsQuery], () => {
    return getMethod(GetSelectedAttractions());
  });

  return (
    <DashboardItems
      items={data}
      isLoading={isLoading}
      title="Sprawdź nasze propozycje!"
      getLinkTo={(item) =>
        `/attraction?id=${item.id}&name=${item.name}&cityId=${item.cityId}`
      }
    />
  );
};

export default SuggestedAttractions;
