import { Box, useMediaQuery, useTheme } from "@mui/material";
import AdSenseFC from "./AdSenseFC";

const ContentAds = () => {
  const theme = useTheme();
  const showBothAds = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <AdSenseFC slot="9009653332" style={{ flex: 1, minHeight: 200 }} />
      {showBothAds && (
        <AdSenseFC slot="7696571661" style={{ flex: 1, minHeight: 200 }} />
      )}
    </Box>
  );
};

export default ContentAds;
