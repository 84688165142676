import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { GetAppInfo, GetAppInfoQuery } from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";

// interface AdSenseI {
//   style?: any;
//   client: any;
//   layout?: any;
//   layoutKey?: string;
//   format?: string;
//   responsive?: boolean;
//   slot: string;
//   path: any;
// }

const AdSenseBase = (props: any) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);
  return (
    <ins
      className={`adsbygoogle`}
      // data-adtest="on"
      style={props.style || { display: "block" }}
      data-ad-client={"ca-pub-8568305028437727"}
      data-ad-slot={props.slot}
      data-ad-layout={props.layout || ""}
      data-ad-layout-key={props.layoutKey || ""}
      data-ad-format={props.format || "auto"}
      data-full-width-responsive={props.responsive || true}
    ></ins>
  );
};

const AdSenseFC = (props: any) => {
  const { data: appInfo } = useQuery([GetAppInfoQuery], () =>
    getMethod(GetAppInfo())
  );

  if (process.env.NODE_ENV === "development") {
    return <Box {...props} style={{ ...props.style, borderStyle: "solid" }} />;
  }

  if (!appInfo?.ads) {
    return null;
  }
  return <AdSenseBase {...props} />;
};

export default AdSenseFC;
